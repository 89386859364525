<template>
  <div class="wrapper">
    <div
        style="margin: 150px auto; background-color: #fff; width: 350px; height: 320px; padding: 20px; border-radius: 10px;box-shadow: 0 0 10px rgba(0,0,0,.4)">
      <div style="margin: 20px 0; text-align: center; font-size: 18px"><b>招聘跟踪管理系统</b></div>
      <el-form :model="user" :rules="rules" ref="userForm" style="width: 100%">
        <el-form-item prop="userName" style="width: 90%;margin: 10px auto">
          <el-input size="large" style="margin: 10px 0" prefix-icon="el-icon-user" v-model="user.userName"></el-input>
        </el-form-item>
        <el-form-item prop="password" style="width: 90%;margin:10px auto">
          <el-input size="medium" style="margin: 10px 0" prefix-icon="el-icon-lock" show-password
                    v-model="user.password"></el-input>
        </el-form-item>
        <el-form-item style="width: 90%;margin:20px auto">
          <el-button type="primary" size="large" autocomplete="off" @click="login" style="width: 100%">登 录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {setRoutes} from "@/router";

export default {
  name: "Login",
  data() {
    return {
      user: {},
      rules: {
        userName: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
          {min: 3, max: 20, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    login() {
      this.$refs['userForm'].validate((valid) => {
        if (valid) {  // 表单校验合法
          this.request.post("/user/login", this.user).then(res => {
            console.log(res)
            if (res.code === "200"){
              localStorage.setItem("user",JSON.stringify(res.data))
              let redirect = this.$route.query.redirect
              console.log("redirect:",redirect)
              if (redirect){
                console.log("有 redirect")
                this.$router.replace({
                  path: redirect
                })
              } else {
                console.log("无 redirect")
                this.$router.replace({
                  path: "/"
                })
              }
              this.$message.success("登录成功")
            }else {
              this.$message.error(res.msg)
            }
          })
        }
      });
    }
  }
}
</script>

<style>
.wrapper {
  height: 100vh;
  background: #5C258D; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #4389A2, #5C258D); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #4389A2, #5C258D); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  overflow: hidden;
}

</style>
